import React from 'react'
import { useState } from 'react'
import { CheckIcon } from '@heroicons/react/solid'

const frequencies = [
  { value: 'monthly', label: 'Monthly', priceSuffix: '' },
  { value: 'annually', label: 'Annually', priceSuffix: ' ht/an' },
]
const tiers = [
  {
    name: 'Offre Découverte',
    id: 'tier-freelancer',
    href: '/maison/visibilite/',
    label: 'Démarrer',
    price: { monthly: 'Gratuite', annually: 'Gratuite' },
    description: 'Gagner en visibilité sur internet',
    description_longue: 'Créez votre fiche membre personnalisée et présentez des informations essentielles sur votre entreprise, vos prestations et services.',
    features: [
      'Page profil personnalisée et visible sur Google',
      'Logo de votre entreprise',
      'Coordonnées en ligne',
      'Éditable à tout moment via une interface dédiée'
    ],
    mostPopular: false,
  },
  {
    name: 'Offre Koudepouce Visibilité',
    id: 'tier-startup',
    href: 'https://buy.stripe.com/8wM2bq0HE8o06c06oI',
    label: 'Démarrer',
    price: { monthly: '360 € HT', annually: '360 € HT' },
    description: 'Améliorez votre présence digitale',
    description_longue: 'Apportez de l’information qualifiée aux lecteurs de votre société. Mettez en avant vos actualités et votre expertise dans votre métier.',
    features: [
      'Offre Découverte comprise',
      'Lien vers votre site internet et réseaux sociaux',
      '3 articles publi rédactionnels (textes, photos, vidéos)',
    ],
    mostPopular: true,
  },
  {
    name: 'Offre Koudepouce Visibilité Plus',
    id: 'tier-enterprise',
    href: 'https://buy.stripe.com/28o17m9ea1ZC0RG00l',
    label: 'Démarrer',
    price: { monthly: '990 € HT', annually: '990 € HT' },
    description: "Développer votre activité grâce à internet.",
    description_longue: "Créez une nouvelle audience ou qui complète votre site internet. Référencez vous mieux sur Google pour avoir plus de chance d’être contacté.",
    features: [
      'Offre Koudepouce Visibilité',
      '12 articles publi rédactionnels (textes, photos, vidéos)',
      'Conseils & optimisation SEO pour vos articles',
    ],
    mostPopular: false,
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function SectionPrix() {
  const [frequency] = useState(frequencies[0])

  return (
    <div className="bg-white py-12 sm:py-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="mt-2 block text-3xl text-center leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl">
            Découvrez les offres Bâti Koudepouce
          </h2>
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
          Tarif forfaitaire et sans engagement
        </p>
        {/* <div className="mt-16 flex justify-center">
          <RadioGroup
            value={frequency}
            onChange={setFrequency}
            className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
          >
            <RadioGroup.Label className="sr-only">Payment frequency</RadioGroup.Label>
            {frequencies.map((option) => (
              <RadioGroup.Option
                key={option.value}
                value={option}
                className={({ checked }) =>
                  classNames(
                    checked ? 'bg-amber-600 text-white' : 'text-gray-500',
                    'cursor-pointer rounded-full py-1 px-2.5'
                  )
                }
              >
                <span>{option.label}</span>
              </RadioGroup.Option>
            ))}
          </RadioGroup>
        </div> */}
        <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {tiers.map((tier) => (
            <div
              key={tier.id}
              className={classNames(
                tier.mostPopular ? 'ring-2 ring-amber-600' : 'ring-1 ring-gray-200',
                'rounded-3xl p-8 xl:p-10'
              )}
            >
              <div className="flex items-center justify-between gap-x-4">
                <h3
                  id={tier.id}
                  className={classNames(
                    tier.mostPopular ? 'text-amber-600' : 'text-gray-900',
                    'text-lg font-semibold leading-8'
                  )}
                >
                  {tier.name}
                </h3>
                {/* {tier.mostPopular ? (
                  <p className="rounded-full bg-amber-600/10 py-1 px-2.5 text-xs font-semibold leading-5 text-amber-600">
                    Most popular
                  </p>
                ) : null} */}
              </div>
              <p className="mt-6 flex items-baseline gap-x-1">
                <span className="text-4xl font-bold tracking-tight text-gray-900">{tier.price[frequency.value]}</span>
                <span className="text-sm font-semibold leading-6 text-gray-600">{frequency.priceSuffix}</span>
              </p>
              <a
                href={tier.href}
                aria-describedby={tier.id}
                target='_blank'
                rel="noreferrer"
                className={classNames(
                  tier.mostPopular
                    ? 'bg-amber-600 text-gray-900 shadow-sm hover:bg-gray-100 ring-1 ring-amber-600'
                    : 'text-gray-900 hover:bg-gray-100 ring-1 ring-amber-600',
                  'mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-600'
                )}
              >
                {tier.label}
              </a>
              <p className="mt-4 text-sm leading-6 text-gray-600">{tier.description}</p>
              <p className="mt-4 text-sm leading-6 text-gray-600">{tier.description_longue}</p>

              <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10">
                {tier.features.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon className="h-6 w-5 flex-none text-amber-600" aria-hidden="true" />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
