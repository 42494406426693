import React from 'react'
import { Disclosure } from '@headlessui/react'
import { PlusIcon, MinusIcon } from '@heroicons/react/outline'

export default function SectionFaq() {
  return (
    <div className="bg-white py-12 sm:py-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
          <h2>
            <span className="mt-2 block text-3xl text-center leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl">
              Demandes d’informations
            </span>
          </h2>
          
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
              <Disclosure as="div" key="Toute entreprise peut-elle s’inscrire sur les offres Koudepouce ?" className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">Toute entreprise peut-elle s’inscrire sur les offres Koudepouce ?</span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusIcon className="h-6 w-6" aria-hidden="true" />
                          ) : (
                            <PlusIcon className="h-6 w-6" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-600">
                      Les offres Koudepouce sont réservées aux entreprises du bâtiment et artisans. Avant validation de votre inscription, nous validons que votre profil correspond à nos critères de sélection et exigences de qualité.
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>

              <Disclosure as="div" key="J’ai déjà un site internet, pourquoi m’inscrire sur Koudepouce ?" className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">J’ai déjà un site internet, pourquoi m’inscrire sur Koudepouce ?</span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusIcon className="h-6 w-6" aria-hidden="true" />
                          ) : (
                            <PlusIcon className="h-6 w-6" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-600">
                      Koudepouce Visibilité vous offre la possibilité de présenter votre entreprise et de faire un lien vers votre site internet. Nous vous permettons aussi de présenter vos services et actualités. Nos actions viennent compléter la visibilité de votre site internet.
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>

              <Disclosure as="div" key="Quelles différences entre Koudepouce Visibilité et Visibilité Plus ?" className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">Quelles différences entre Koudepouce Visibilité et Visibilité Plus ?</span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusIcon className="h-6 w-6" aria-hidden="true" />
                          ) : (
                            <PlusIcon className="h-6 w-6" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-600">
                      Si vous voulez améliorer votre présence digitale, Koudepouce Visibilité peut suffire. En revanche, si vous voulez améliorer votre référencement sur Google et autres moteurs de recherche, il est préférable de souscrire l’offre Visibilité Plus. Cette dernière contient 12 articles publi rédactionnels contre 3 dans la première offre.
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>

              <Disclosure as="div" key="Qu’est ce qu’un article publi-rédactionnel ?" className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">Qu’est ce qu’un article publi-rédactionnel ?</span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusIcon className="h-6 w-6" aria-hidden="true" />
                          ) : (
                            <PlusIcon className="h-6 w-6" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-600">
                      Le but d’un article publi rédactionnel est d’apporter de l’information sur votre entreprise, vos produits ou services. Un tel article permet de vous promouvoir et de faire passer un message sur votre positionnement et vos valeurs. Le publi-rédactionnel est personnalisable et il peut contenir des textes, photos, vidéos et documentations. Son contenu est informationnel avant tout.
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>

              <Disclosure as="div" key="Avez des exemples de contenus publi rédactionnels et de résultats ?" className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">Avez-vous des exemples de contenus publi rédactionnels et de résultats ?</span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusIcon className="h-6 w-6" aria-hidden="true" />
                          ) : (
                            <PlusIcon className="h-6 w-6" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-600">
                      Cette société a cherché à compléter sa présence en ligne grâce à des publi-rédactions comme <a className="underline" href="/maison/fissures-maison/expertise/dubois-expertise/" target="_blank" referrer="noreferrer">celle-ci</a>. L’article apparaît en première page Google sur <a className="underline" href="https://www.google.com/search?q=expertise+fissure+lyon" target="_blank" referrer="noreferrer">expertise fissure lyon</a>.
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>

              <Disclosure as="div" key="Qui écrit le contenu de l’article publi-rédactionnel ?" className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">Qui écrit le contenu de l’article publi-rédactionnel ?</span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusIcon className="h-6 w-6" aria-hidden="true" />
                          ) : (
                            <PlusIcon className="h-6 w-6" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-600">
                      L’article peut être rédigé par soit Koudepouce, soit par l’entreprise qui annonce. Il peut y avoir une co-écriture. Exemple : Koudepouce structure l’article, pose des questions dans le cadre d’une interview et l’entreprise y répond. Dans tous les cas, Koudepouce et l’entreprise valident l’article avant diffusion en sorte qu’il corresponde aux attentes de chacun.
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
          </dl>
        </div>
      </div>
    </div>
  )
}