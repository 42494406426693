import React from "react"
import { Helmet } from "react-helmet"

import { StaticImage } from "gatsby-plugin-image"

import Header from "src/components/headers/headerLanding"
import Footer from "src/components/footers/footer"
import Form from "src/components/formulaires/formMaisonVisibilite"
import SectionPrix from "src/components/landing/sectionPrix"
import SectionFaq from "src/components/landing/sectionFaq"

export default function index() {

  return (
    <div className="bg-white">
      <Helmet>
        <title>Koudepouce</title>
        <meta name="description" content="Koudepouce simplifie votre vie au quotidien." />
      </Helmet>
      <header className="sticky top-0 z-50 shadow-lg bg-white">
        <Header />
      </header>

      <main className="relative">
        {/* Section Formulaire */}
        <div className="relative isolate overflow-hidden bg-gray-200 py-8 lg:py-24">
          <div className="blur-sm absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center">
            <StaticImage
              className="hidden blur-sm absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center lg:block"
              src="../../images/koudepouce-visibilite.jpg" 
              alt=""
            />
          </div>
          <div id="formulaire_inscription"></div>
          <div className="mx-auto max-w-7xl px-4 lg:px-8">
            <div className="rounded-xl lg:grid lg:grid-cols-2 backdrop-blur-sm bg-white/30">
              <div className=" px-4 py-4 mx-auto max-w-2xl pb-4 lg:mx-0 text-gray-800">
                <h2 className="text-3xl font-bold tracking-tight text-gray sm:text-4xl">Gagnez en visibilité grâce à Koudepouce</h2>
                <p className="mt-6 text-lg leading-8 text-gray">
                Artisans et entreprises du bâtiment : avec Koudepouce, vous disposez d’une solution complète pour vous faire connaître sur internet
                </p>
                <ul className="mt-6 text-lg leading-8 text-gray list-disc pl-8">
                  <li>
                  Fiche membre personnalisée et visible sur Google
                  </li>
                  <li>
                  Vitrine digitale de votre entreprise
                  </li>
                  <li>
                  Présentation de vos prestations et services
                  </li>
                </ul>
                {/* <p className="mt-6 text-lg leading-8 text-gray">
                La formule Koudepouce Premium peut être testée gratuitement en sans engagement pendant 30 jours. 
Puis elle est proposée à partir de 30€ HT / mois.
                </p>
                <p>
                Vous ne disposez pas encore de fiche membre ? <a href="/solution/membre/welcome/" className="text-spring-bleu">Cliquez-ici</a>
                </p> */}
              </div>
              
              <div>
                <Form />
              </div>
            </div>
          </div>
        </div>

        {/* Section Prix */}

        <SectionPrix />

        {/* Section FAQ */}

        <SectionFaq />




      </main>

      <footer>
        <Footer />
      </footer>
    </div>
  )
}